<template>
  <div class="footer-bar flex flex-vc">
    <!-- 底部导航信息展示 -->
    <div class="container flex flex-jsb">
      <div class="left flex flex-v">
        <div class="email flex flex-vc">
          <img src="@/assets/inner-layout/email.png" alt="" />
          <span>softdev@zero2ipo.com.cn</span>
        </div>
        <div class="location flex flex-vc">
          <img src="@/assets/inner-layout/location.png" alt="" />
          <span>北京市朝阳区霄云路40号院1号楼国航世纪大厦10层</span>
        </div>
        <div class="info flex flex-vc">
          <p></p>
          <div class="flex flex-v">
            <div class="tp flex flex-vc">
              <span
                ><a
                  href="https://beian.miit.gov.cn/#/Integrated/index"
                  target="_blank"
                  >京ICP备17028573号-11</a
                ></span
              >
              <span>
                <a
                  class="flex flex-vc"
                  target="_blank"
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502047758"
                >
                  <img
                    src="@/assets/guohui.jpg"
                    alt=""
                    style="margin-right: 5px; width: 20px"
                  />
                  京公网安备 11010502047758号
                </a>
              </span>
            </div>
            <div class="bot">
              <span>Copyright &#169; 2020-2021</span>
              <span>北京清科创业信息咨询有限公司版权所有</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.footer-bar {
  height: 207px;
  background-color: var(--footerBgColor);
  .container {
    color: var(--whiteColor);
    font-size: 14px;
  }
  .left {
    .location {
      margin-top: 20px;
      img {
        width: 18px;
        margin-right: 4px;
      }
    }
    .email {
      img {
        width: 18px;
        margin-right: 4px;
      }
    }
    .info {
      margin-top: 13px;
      font-size: 12px;
      color: var(--whiteColor);
      line-height: 17px;
      p {
        width: 18px;
        margin-right: 4px;
      }
      .tp {
        margin-bottom: 5px;
      }
      span {
        margin-right: 12px;
        a {
          font-size: 12px;
          color: var(--whiteColor);
        }
      }
    }
  }
}
</style>
