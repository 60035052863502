<template>
  <div class="inner-layout-body">
    <div class="inner-layout__main">
      <!-- 顶部工具栏 -->
      <header-bar id="pageHeader"></header-bar>

      <div class="inner-layout__page">
        <!-- 路由页面 -->
        <transition name="page" mode="out-in">
          <!-- <keep-alive> -->
          <router-view />
          <!-- </keep-alive> -->
        </transition>
      </div>
      <footer-bar id="pageFooter"></footer-bar>
    </div>
  </div>
</template>

<script>
import { HeaderBar, FooterBar } from "./components";

export default {
  components: { HeaderBar, FooterBar },
  data() {
    return {
      rightMenuVisible: false,
    };
  },
  computed: {},
  created() {
    // 给窗口绑定滚动监听事件
    window.addEventListener("scroll", this.windowScrollListener);
  },
  methods: {
    windowScrollListener() {
      // 获取滚动条的高度
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;

      // 获取窗口高度
      let winHeight = 900;
      if (window.innerHeight) winHeight = window.innerHeight;
      else if (document.body && document.body.clientHeight)
        winHeight = document.body.clientHeight;
      if (scrollTop >= winHeight) {
        // 利用布尔值控制元素的显隐
        this.rightMenuVisible = true;
      }
      if (scrollTop < winHeight) {
        this.rightMenuVisible = false;
      }
    },
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.windowScrollListener);
  },
};
</script>

<style lang="scss" scoped>
.inner-layout-body {
  // min-width: 1280px;
  min-width: 1312px;
  overflow-x: auto;
  overflow-y: auto;
}
.inner-layout__main {
  padding-top: 60px;
  .inner-layout__page {
    .page {
      &-enter {
        opacity: 0;
        transform: translateX(-30px);
      }

      &-leave-to {
        opacity: 0;
        transform: translateX(30px);
      }

      &-enter-active,
      &-leave-active {
        transition: all 0.3s ease;
      }
    }
  }
}
// .animate__animated.animate__zoomIn,
// .animate__animated.animate__zoomOut {
//   --animate-duration: 0.5s;
// }
</style>
