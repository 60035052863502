<template>
  <div class="header-bar">
    <div class="container flex flex-vc flex-jsb">
      <a href="" class="logo">
        <img src="@/assets/logo.png" alt="" />
      </a>
      <div class="header-right-side flex flex-vc">
        <ul class="header-menu flex flex-vc" ref="menus">
          <div class="active-bar"></div>
          <li
            v-for="item in navArr"
            :key="item.path"
            :class="[
              item.path === activePath ? 'nav-active' : '',
              item.children && item.children.length > 0 ? 'hasChildren' : '',
            ]"
            @click="handleClick(item)"
          >
            <!-- 存在二级菜单 -->
            <div
              v-if="item.children && item.children.length > 0"
              class="dropdown-box"
              @mouseenter="enterView"
              @mouseleave="leaveView"
            >
              <div class="sub-menu flex flex-vc flex-jsb">
                {{ item.name }}<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <div class="drop-view">
                <el-collapse-transition>
                  <div v-show="showDropdown">
                    <div class="sanjiao"></div>
                    <div class="dropdown">
                      <div
                        v-for="subitem in item.children"
                        :key="subitem.path"
                        :disabled="pathUrl == subitem.path"
                        @click="submenuClick(item, subitem)"
                        class="dropdown-item"
                      >
                        <span class="item-value">{{ subitem.name }}</span>
                      </div>
                    </div>
                  </div>
                </el-collapse-transition>
              </div>
            </div>
            <!-- 只有一级菜单 -->
            <span v-else>{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    langType() {
      return this.$i18n.locale == "zh" ? "简体中文" : "繁體中文";
    },
    navArr() {
      return this.$t("innerLayout.header.nav") || [];
    },
    pathUrl() {
      return this.$route.path;
    },
  },
  data() {
    return {
      activeName: "second",
      activeIndex: "1",
      activeIndex2: "1",
      activePath: "",
      subItemPath: "",
      beforeRouteName: "",
      showDropdown: false,
    };
  },

  watch: {
    // 每次地址改变 计算执行动画
    // activePath() {
    // this.resetTabActivePosition(this.$refs.menus);
    // },
    //监听当前地址
    $route() {
      // console.log(this.$route);
      // this.beforeRoutePath = val.path;
      this.initBar();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(vm);
    });
  },
  mounted() {
    //初始化动画路径
    this.initBar();
  },
  methods: {
    enterView() {
      this.showDropdown = true;
    },
    leaveView() {
      this.showDropdown = false;
    },
    leaveViewDrop() {
      // this.showDropdown = false;
    },
    // 初始化或者变动底部bar
    initBar() {
      const path = this.$route.path.split("/")[1];
      // 储存上次访问的tabbar页面
      const filterPath = this.navArr.filter((item) => item.path === "/" + path);
      if (filterPath && filterPath.length > 0) {
        this.activePath = filterPath[0].path;
        sessionStorage.setItem("activePath", this.activePath);
      } else {
        this.activePath = sessionStorage.getItem("activePath") || "/home";
      }
      // 计算
      this.resetTabActivePosition(this.$refs.menus);
    },
    // 底部bar跟随距离计算
    resetTabActivePosition($el) {
      this.$nextTick(() => {
        const activeEl = $el.querySelector("li.nav-active");
        // console.log(activeEl);
        if (!activeEl) return;
        // console.log(activeEl.clientWidth);
        const lineEl = $el.querySelector(".active-bar");
        const style = getComputedStyle(activeEl);
        // const pl = style.paddingLeft.match(/\d+/)[0] * 1;
        const pr = style.paddingRight.match(/\d+/)[0] * 1;
        // const w = style.width.match(/\d+/)[0] * 1;
        lineEl.style.transform =
          "translateX(" +
          (activeEl.offsetLeft +
            activeEl.offsetWidth / 2 -
            lineEl.clientWidth / 2 -
            pr / 2) +
          "px)";
        // lineEl.style.width = w - pl - pr + "px";
      });
    },
    // 语言切换
    toggleLang(lang) {
      if (lang == "zh") {
        sessionStorage.setItem("language", "zh");
        this.$i18n.locale = sessionStorage.getItem("language");
      } else if (lang == "hk") {
        sessionStorage.setItem("language", "hk");
        this.$i18n.locale = sessionStorage.getItem("language");
      }
    },
    //一级tab点击
    handleClick(item) {
      if (item.children && item.children.length > 0) return;
      this.subItemPath = "";
      this.$router.push(item.path);
    },
    //二级tab点击
    submenuClick(item, subItem) {
      this.subItemPath = subItem.path;
      this.$router.push(subItem.path);
      this.showDropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-bar {
  background: var(--HeaderBgColor);
  width: 100%;
  height: 60px;
  font-size: 16px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  .container {
    height: 100%;
  }
  .logo {
    width: 146px;
    // display: inline-block;
    img {
      width: 100%;
    }
  }
  .header-right-side {
    height: 100%;
    .header-menu {
      height: 100%;
      color: var(--whiteColor);
      position: relative;
      .sub-menu {
        color: var(--whiteColor);
        font-size: 16px;
        position: relative;
        i {
          color: var(--iconGray);
          font-size: 18px;
          font-weight: bolder;
          position: absolute;
          right: -20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .active-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 24px;
        height: 2px;
        border-radius: 5px;
        background-color: var(--whiteColor);
        z-index: 1;
        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        list-style: none;
      }
      li {
        margin: 0 28px;
        cursor: pointer;
        &.nav-active,
        &:hover {
          font-weight: 500;
        }
        &.hasChildren {
          padding-right: 20px;
          position: relative;
        }
        .dropdown-box {
          .drop-view {
            position: absolute;
            width: 100px;
            left: 50%;
            transform: translateX(-50%);
            top: 16px;
            z-index: 100;
            padding-top: 12px;
            .sanjiao {
              height: 5px;
              position: relative;
              &::after {
                content: "";
                width: 0;
                height: 0;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #fff;
                position: absolute;
                left: 30px;
                top: 0px;
                border-radius: 0 0 2px;
              }
            }
          }
          .dropdown {
            position: relative;
            margin-top: 2px;
            background: var(--whiteColor);
            // position: absolute;
            // width: 100px;
            // left: 50%;
            // transform: translateX(-50%);
            // top: 35px;
            border-radius: 2px;
            box-shadow: 0px 6px 12px 0px rgba(54, 95, 163, 0.16);

            .dropdown-item {
              height: 40px;
              padding: 0 10px;
              line-height: 40px;
              font-size: 14px;
              color: var(--blackColor);
              font-weight: 400;
              &:hover {
                color: var(--blue);
                background: rgba(71, 141, 255, 0.1);
              }
              &[disabled="disabled"] {
                color: var(--blue);
              }
              &:nth-child(1) {
                .item-value {
                  border-top: none;
                }
              }
              .item-value {
                display: inline-block;
                height: 100%;
                width: 100%;
                border-top: 1px solid #eef1f3;
              }
            }
          }
        }
      }
    }
    .switch-lang {
      margin-left: 13px;
      width: 120px;
      height: 32px;
      padding: 0 9px 0 16px;
      color: var(--whiteColor);
      font-size: 12px;
      line-height: 32px;
      border-radius: 32px;
      border: 1px solid #3b424b;
      i {
        font-size: 18px;
        font-weight: bolder;
        color: var(--iconGray);
      }
    }
  }
}
.el-dropdown-menu__item.is-disabled {
  color: var(--blue);
}
.el-dropdown-menu {
  // margin-left: 20px;
  // left: 20px;
}
</style>
